import React, { Fragment } from "react"
import gif from "./roamingGif.mp4"

const howToFind = (
  <Fragment>
    <div className="u-side-pad u-top-pad">
      <h3 className="heading--small">Find Roaming Hut</h3>
      <p>
        True to its name this Urban Hut roams to different locations during the
        New Zealand Festival of the Arts (21 Feb – 15 March). See scheduled
        dates and locations below to plan ahead for when you want to catch it.
      </p>
      <p>
        Follow the Roaming Hut pin on the map to find the hut in session. It
        will move each day of the schedule. If you see it on the map, Roaming
        Hut is there right now! Catch it if you can!
      </p>
      <p>
        If you don’t see the pin on the map, refer to the schedule and look
        again during one of those session times. Actual session times may differ
        from scheduled times. Keep updated by following the hut on the socials:
      </p>

      <h3 className="heading--small">Live updates</h3>
      <p>
        Visit{" "}
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://www.instagram.com/urbanhutclub/"
        >
          @UrbanHutClub
        </a>{" "}
        on Instagram
        <br />
        Follow{" "}
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://twitter.com/Kemi_Niko"
        >
          @Kemi_Niko
        </a>{" "}
        on Twitter
      </p>
    </div>
    <video
      autoPlay
      loop
      muted
      playsInline
      src={gif}
      style={{ width: "100%" }}
    ></video>
    <div className="u-side-pad u-top-pad">
      <h3>Thursday 20 Feb</h3>
      <p>
        Mt. Vic.
        <br />
        5.30pm - 7.30pm
      </p>

      <h3>Friday 21 Feb</h3>
      <p>
        Odlins plaza, Waterfront
        <br />
        8.30am - 10.30am
      </p>

      <h3>Saturday 22 Feb</h3>
      <p>
        Te Rauparaha Arena, Porirua
        <br />
        10.30am - 12.30
      </p>

      <h3>Sunday 23 Feb</h3>
      <p>
        Te Rauparaha Arena, Porirua
        <br />
        1pm – 4pm
      </p>

      <h3>Monday 24 Feb</h3>
      <p>
        RNZB lawn, Michael Fowler Centre
        <br />
        7.30am - 9.30am
      </p>

      <h3>Thursday 27 Feb</h3>
      <p>
        Secret Location
        <br />
        4.30pm – 6.30pm
      </p>

      <h3>Saturday 29 Feb</h3>
      <p>
        Performance Arcade, Waterfront
        <br />
        10am – 12noon
      </p>

      <h3>Sunday 1 March</h3>
      <p>
        Secret Location
        <br />
        12- 2pm
      </p>

      <h3>Monday 2 March</h3>
      <p>
        Waihinahina Park, Newlands
        <br />
        10.45am - 12.45
      </p>

      <h3>Tuesday 3 March</h3>
      <p>
        Seaweek, Secret Location
        <br />
        11.30am – 1.30pm
      </p>

      <h3>Wednesday 4 March</h3>
      <p>
        Lower Hutt Events Centre
        <br />
        6pm – 9pm
      </p>

      <h3>Thursday 5 March</h3>
      <p>
        Dowse Museum, Lower Hutt
        <br />
        2pm – 4pm
      </p>

      <h3>Saturday 7 March</h3>
      <p>
        Odlins plaza, Waterfront
        <br />
        12 – 2pm
      </p>

      <h3>Sunday 8 March</h3>
      <p>
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="http://www.newtownfestival.org.nz/roaming-hut/"
        >
          Newtown Festival
        </a>
        , Carrara Park
        <br />
        5pm - 8pm
      </p>

      <h3>Monday 9 March</h3>
      <p>
        Papawai Reserve, Mount Cook
        <br />
        5pm – 7pm
      </p>

      <h3>Tuesday 10 March</h3>
      <p>
        Under the sails, TSB Arena
        <br />
        5.30pm – 7.30pm
      </p>

      <h3>Wednesday 11 March</h3>
      <p>
        Under the sails, TSB Arena
        <br />
        5.30pm – 7.30pm
      </p>

      <h3>Thursday 12 March</h3>
      <p>
        RNZB lawn, Michael Fowler Centre
        <br />
        9.30am – 11.30am
      </p>

      <h3>Saturday 14 March</h3>
      <p>
        National Library, Thorndon
        <br />
        12 – 3.30pm
      </p>
    </div>
  </Fragment>
)

export default howToFind
