import React, { Fragment } from "react"
import Layout from "../../../components/layout/layout"
import howToFind from "../../../components/hut-data/hut6/howToFind"
import aboutContent from "../../../components/hut-data/hut6/about"
import HutPageLayout from "../../../components/hutPageLayout/hutPageLayout"
import { AccordionContent } from "../../../components/accordion/accordion"
import hutTitle from "../../../components/hut-data/hut6/hut-title.svg"
import aboutImg from "../../../components/hut-data/hut6/about-img.jpg"

import { GeolocationContext } from "../../../AppState"
import HutMap from "../../../components/hutMap/hutMap"

const accordionContent: AccordionContent[] = [
  {
    title: "How to Find",
    backgroundColour: "#ffcc99",
    inner: howToFind,
  },
  {
    title: "About",
    inner: (
      <Fragment>
        <img src={aboutImg} alt="" className="width-full" />
        {aboutContent}
      </Fragment>
    ),
  },
]

function Hut() {
  return (
    <Layout title="Roaming Hut">
      <GeolocationContext.Consumer>
        {context => {
          const { foundHuts } = context
          return (
            <div className="map__info-container">
              {/* TODO: change bounds */}
              <HutMap
                isHutPage
                height="320px"
                foundHuts={foundHuts}
                bounds={[
                  [174.56459518292615, -41.11264358148923],
                  [175.03923850328897, -41.36305910809143],
                ]}
              />
            </div>
          )
        }}
      </GeolocationContext.Consumer>
      <HutPageLayout
        hutId="hut6"
        heading="Roaming Hut"
        headingImg={hutTitle}
        headingColour="#008080"
        description="You never know who you could be sharing a backcountry hut with. Crafted from salvaged caravan parts, reclaimed heart rimu and an old Scout tent, Roaming Hut brings you such encounters within an urban shelter like no other. Who will you meet there?"
        accordionContent={accordionContent}
      />
    </Layout>
  )
}

export default Hut
